import { useEffect } from 'react'

import { useGlobalContext } from '../../state/global'

function usePageStatus(transitionStatus, page) {
  const [, dispatchGlobal] = useGlobalContext()

  useEffect(() => {
    switch (transitionStatus) {
      case 'entering':
        dispatchGlobal({ type: 'setPageEntering', newPageEntering: page })
        break
      case 'entered':
        dispatchGlobal({ type: 'setPageEntered', newPageEntered: page })
        break
      case 'exiting':
        dispatchGlobal({ type: 'setPageExiting', newPageExiting: page })
        break
      case 'exited':
        dispatchGlobal({ type: 'setPageExited', newPageExited: page })
        break
      default:
    }
  }, [transitionStatus])
}

export default usePageStatus
