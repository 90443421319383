import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import * as utils from '../lib/utils'
import { useGlobalContext } from '../state/global'

import SEO from '../components/seo/seo'
import Modal from '../components/modal/modal'
import Bio from '../components/bio/bio'
import usePageStatus from '../hooks/usePageStatus/usePageStatus'

export default ({ data, transitionStatus }) => {
  const about = utils.getEntryFromData(data)
  const [, dispatchGlobal] = useGlobalContext()
  const socialMediaImage = about.socialMediaImage[0]
    ? about.socialMediaImage[0].thumbnail
    : false

  useEffect(() => {
    dispatchGlobal({ type: 'setIsFilterOn', newIsFilterOn: true })
    dispatchGlobal({ type: 'setModalTransition', newModalTransition: false })
    dispatchGlobal({ type: 'setPage', newPage: 'about' })

    return () => {
      dispatchGlobal({ type: 'setIsFilterOn', newIsFilterOn: false })
      dispatchGlobal({ type: 'setModalTransition', newModalTransition: true })
    }
  }, [])

  usePageStatus(transitionStatus, 'about')

  return (
    <>
      <SEO
        title={about.metaTitle || about.title}
        description={about.metaDescription}
        image={socialMediaImage}
      />
      <Modal transitionStatus={transitionStatus} transition="fade">
        <Bio content={about.projectDescription.content} />
      </Modal>
    </>
  )
}

export const query = graphql`
  {
    craft {
      entry(section: about) {
        ... on Craft_About {
          uri
          title
          metaTitle
          metaDescription
          projectDescription {
            content
          }
          socialMediaImage {
            thumbnail: url(transform: metaImage)
          }
        }
      }
    }
  }
`
