import React from 'react'

import './bio.scss'

function Bio({ content }) {
  return (
    <article
      className="bio h1 underlineHover"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default Bio
