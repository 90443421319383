import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { disableScrolling, enableScrolling } from '../../lib/utils'

import './modal.scss'

const createStyle = (transitionStatus, transition) => {
  switch (transition) {
    case false:
      return {}

    case 'move':
      switch (transitionStatus) {
        case 'entering':
          return {
            transform: 'translateY(0)',
          }
        case 'entered':
          return {
            transform: 'translateY(0)',
          }
        case 'exiting':
          return {
            transform: 'translateY(-100%)',
            transitionDuration: '2000ms',
          }
        default:
          return {
            transform: 'translateY(100vh)',
          }
      }

    case 'fade':
      switch (transitionStatus) {
        case 'entering':
          return {
            opacity: '1',
          }
        case 'entered':
          return {
            opacity: '1',
          }
        case 'exiting':
          return {
            opacity: '0',
          }
        default:
          return {
            opacity: '0',
          }
      }
    default:
  }
}

function Modal({
  children,
  transitionStatus = false,
  transition = false,
  setRef = false,
  ...props
}) {
  useEffect(() => {
    disableScrolling()

    return () => {
      enableScrolling()
    }
  }, [])

  const ref = setRef ? { ref: setRef } : {}

  const transitionStyle = createStyle(transitionStatus, transition)

  return (
    <article className="modal" {...props} {...ref}>
      <div className="modal__inner" style={transitionStyle}>
        {children}
      </div>
    </article>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Modal
